







































































// CORE
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

// COMPONENTS
import Card from '@/components/cards/Card.vue'
import Confirmation from '@/components/modals/Confirmation.vue'

// STORE
import FleetPenaltyModule from '@/store/modules/fleet/penalty'
import DriverPenaltyModule from '@/store/modules/driver/driver-penalty'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// INTERFACES
import { PenaltyResource, PenaltyStatusEnum } from '@/store/types'
import { DateTime } from 'luxon'
import { parseDate } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    Card,
  },
})
/**
 * Этот компонент не карточка, а часть карточки.
 * Его ключевым словом будет Content, а не Card.
 */
export default class PenaltyCardContent extends Mixins(NotifyMixin, SystemMixin) {
  @Prop({
    default: 'red',
    validator (value: string) :boolean {
      return !!value.match(/(red|blue)/)
    },
  })
  readonly mod!: string

  @Prop({ default: () => ({}) })
  private item!: PenaltyResource

  @Ref()
  confirm!: Confirmation

  private get isShowButton () :boolean {
    return this.item.status === PenaltyStatusEnum.NEW || this.item.status === PenaltyStatusEnum.NOT_PAID
  }

  private get innerItem (): PenaltyResource {
    return {
      ...this.item,
      protocolDate: this.validateDate(this.item.protocolDate, 'protocol_date'),
      violationDate: this.validateViolationDate(this.item.violationDate),
      dateOfGettingOriginal: this.validateDate(this.item.dateOfGettingOriginal, 'date_of_getting_original'),
      discountEndDate: this.validateDate(this.item.discountEndDate, 'discount_end_date'),
    }
  }

  // private confirmLitigate (id: number) {
  //   this.confirm.open(
  //     'Оспаривание штрафа',
  //     'Это действие нельзя будет отменить, также не будет доступна оплата по штрафу.',
  //     {
  //       buttonConfirmText: 'Оспорить',
  //     },
  //   )
  //     .then(this.litigate.bind(this, id))
  // }

  private litigate (id: number) {
    if (this.role === 'fleet') {
      FleetPenaltyModule.submitForDispute(id)
        .then(() => {
          this.$bus.$emit('penaltiesNeedUpdate')
        })
    } else if (this.role === 'driver') {
      DriverPenaltyModule.submitForDispute(id)
        .then(() => {
          this.$bus.$emit('penaltiesNeedUpdate')
        })
    }
  }

  // NOTE: Иногда с бэка приходит дата 0001-01-01
  // временное решение: обработка данной ошибки в console.warn и скрытие поля в карточке
  private minPenaltyDate = DateTime.fromISO('1900-01-01')
  private validateDate (date: string, name: string) {
    if (date && DateTime.fromFormat(date.slice(0,10), 'yyyy-MM-dd') < this.minPenaltyDate) {
      console.warn(`No valide date (${name}): ${date}. Penalty id: ${this.item.id}.`)

      return ''
    } else {
      return parseDate(date)
    }
  }
  private validateViolationDate (date: string) {
    const dateTime = DateTime.fromFormat(`${date}`, 'yyyy-MM-dd HH:mm:ssZZZ')

    if (dateTime < this.minPenaltyDate) {
      console.warn(`No valide date (violation_date): ${date}. Penalty id: ${this.innerItem.id}.`)

      return ''
    } else {
      return dateTime.toFormat('dd.MM.yy HH:mm')
    }
  }
}
