








































































// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// LIBRARIES
import { DateTime } from 'luxon'

// COMPONENTS
import Card from '@/components/cards/Card.vue'

// INTERFACES
import { PenaltyResource, PenaltyStatusEnum } from '@/store/types'

// HELPERS
import { formatCost, parseDate } from '@/utils/functions'


interface PenaltyResourceWithChecked extends PenaltyResource {
  checked: boolean,
}

/**
 * Этот компонент не карточка, а часть карточки.
 * Его ключевым словом будет Header, а не Card.
 */
@Component({
  components: {
    Card,
  },
})
export default class PenaltyCardHeader extends Vue {
  @Prop({ default: () => ({}) })
  private item!: PenaltyResourceWithChecked

  @Prop({ default: false })
  private isVisibleVin!: boolean

  private penaltyStatusEnum = PenaltyStatusEnum

  private formatCost = formatCost

  private get isNotPaid () :boolean {
    return this.innerItem.status === this.penaltyStatusEnum.NEW || this.innerItem.status === this.penaltyStatusEnum.NOT_PAID
  }

  private get discountEnd (): string {
    const date = DateTime.fromFormat(`${this.innerItem.discountEndDate}`, 'dd.MM.yy')
    const now = DateTime.now()

    if (now.valueOf() <= date.valueOf()) {
      return 'line-through'
    } else {
      return ''
    }
  }

  private get innerItem (): PenaltyResourceWithChecked {
    return {
      ...this.item,
      protocolDate: this.validateDate(this.item.protocolDate, 'protocol_date'),
      violationDate: this.validateViolationDate(this.item.violationDate),
      dateOfGettingOriginal: this.validateDate(this.item.dateOfGettingOriginal, 'date_of_getting_original'),
      discountEndDate: this.validateDate(this.item.discountEndDate, 'discount_end_date'),
    }
  }

  private get checked (): boolean {
    return this.item.checked
  }

  private set checked (checked: boolean) {
    this.$emit('checked', checked)
  }

  // NOTE: Иногда с бэка приходит дата 0001-01-01
  // временное решение: обработка данной ошибки в console.warn и скрытие поля в карточке
  private minPenaltyDate = DateTime.fromISO('1900-01-01')
  private validateDate (date: string, name: string) {
    if (date && DateTime.fromFormat(date.slice(0,10), 'yyyy-MM-dd') < this.minPenaltyDate) {
      console.warn(`No valide date (${name}): ${date}. Penalty id: ${this.item.id}.`)

      return ''
    } else {
      return parseDate(date)
    }
  }
  private validateViolationDate (date: string) {
    const dateTime = DateTime.fromFormat(`${date}`, 'yyyy-MM-dd HH:mm:ssZZZ')

    if (dateTime < this.minPenaltyDate) {
      console.warn(`No valide date (violation_date): ${date}. Penalty id: ${this.innerItem.id}.`)

      return ''
    } else {
      return dateTime.toFormat('dd.MM.yy HH:mm')
    }
  }
}
