
























































/**
 * Компонент ExpansionPanels для информации по автомобилю
 */
import { Component, Prop, Vue } from 'vue-property-decorator'

//COMPONENTS
import CarCharacteristics from '@/components/data/CarInfoExpansionPanelsContent/CarCharacteristics.vue'
import CarTermsOfAgreement from '@/components/data/CarInfoExpansionPanelsContent/CarTermsOfAgreement.vue'
import CarDrivers from '@/components/data/CarInfoExpansionPanelsContent/CarDrivers.vue'
import CarPolicy from '@/components/data/CarInfoExpansionPanelsContent/CarPolicy.vue'
import CarRequests from '@/components/data/CarInfoExpansionPanelsContent/CarRequests.vue'
import CarPenalties from '@/components/data/CarInfoExpansionPanelsContent/CarPenalties.vue'
import { CountResource, PenaltyShortResource, PenaltyStatusEnum, RequestResource } from '@/store/types'
import CarInfoModule from '@/store/modules/fleet/car-info'
import FiltersPanel from '@/components/FiltersPanel.vue'

@Component({
  components: {
    CarPenalties,
    CarRequests,
    CarDrivers,
    CarCharacteristics,
    CarTermsOfAgreement,
    CarPolicy,
    FiltersPanel,
  },
})
export default class ExpansionPanelsCarInfo extends Vue {
  @Prop({ required: true })
  private carInfo!: any

  private activePanel = []

  /**
   * COMPUTED PROPERTIES
   */
  private get requests () {
    let requests: RequestResource[] = []
    if(CarInfoModule.carInfo.request){
      switch (this.requestActiveStatus) {
      case 'done': {
        requests = CarInfoModule.carInfo.request.filter((item: RequestResource) => item.status === 'Размещено')
        break
      }
      case 'in-progress': {
        requests = CarInfoModule.carInfo.request.filter((item: RequestResource) => item.status === 'В работе')
        break
      }
      default: {
        requests = CarInfoModule.carInfo.request
        break
      }
      }
    }
    return requests

  }

  private get penalties () {
    let penalties: PenaltyShortResource[] = []
    if(CarInfoModule.carInfo.penalty){
      switch (this.penaltyActiveStatus) {
      case PenaltyStatusEnum.CHALLENGING: {
        penalties = CarInfoModule.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.CHALLENGING)
        break
      }
      case PenaltyStatusEnum.NOT_PAID: {
        penalties = CarInfoModule.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.NOT_PAID)
        break
      }
      case PenaltyStatusEnum.PAID: {
        penalties = CarInfoModule.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.PAID)
        break
      }
      case PenaltyStatusEnum.NEW: {
        penalties = CarInfoModule.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.NEW)
        break
      }
      default: {
        penalties = CarInfoModule.carInfo.penalty
        break
      }
      }
    }
    return penalties
  }

  private get requestStatuses (): CountResource[] {
    return CarInfoModule.requestStatuses
  }

  private get requestActiveStatus (): number | string | undefined {
    return CarInfoModule.requestStatusId ? CarInfoModule.requestStatusId : 'all'
  }

  private set requestActiveStatus (value: number | string | undefined) {
    CarInfoModule.setRequestStatusId(value)
  }

  private get penaltyStatuses (): CountResource[] {
    return CarInfoModule.penaltyStatuses
  }

  private get penaltyActiveStatus (): number | string | undefined {
    return CarInfoModule.penaltyStatusId ? CarInfoModule.penaltyStatusId : 'all'
  }

  private set penaltyActiveStatus (value: number | string | undefined) {
    CarInfoModule.setPenaltyStatusId(value)
  }
}
