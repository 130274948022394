



































































// Core
import { Component, Mixins, Prop } from 'vue-property-decorator'

// Mixins
import SystemMixin from '@/mixins/SystemMixin'

// Helpers
import { openNewTab, parseDate } from '@/utils/functions'

// Types
import { InsuranceResource } from '@/store/types'

@Component

export default class CarPolicy extends Mixins(SystemMixin) {
  @Prop({ required: true })
  private carPolicy!: InsuranceResource

  private get innerValue (): InsuranceResource {
    const result = {
      ...this.carPolicy,
    }

    if (this.carPolicy?.osago?.startDate) {
        result.osago = {
          ...this.carPolicy.osago,
          endDate: parseDate(this.carPolicy.osago.endDate),
          startDate: parseDate(this.carPolicy.osago.startDate),
      }
    }

    if (this.carPolicy?.casco?.startDate) {
      result.casco = {
        ...this.carPolicy.casco,
        endDate: parseDate(this.carPolicy.casco.endDate),
        startDate: parseDate(this.carPolicy.casco.startDate),
      }
    }

    return result
  }

  private downloadPDF (cardId: number) {
    let dataObj = {
      insurance_id: cardId,
    }
    openNewTab(`/insurance-pdf/${+this.entity}`, dataObj, false)
  }

}
