// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'
import {
  CarParksCarCarIdGetRequest,
  CountResource, PenaltyShortResource, PenaltyStatusEnum, RequestResource,
  VehicleDataResource,
} from '@/store/types'


@Module({
  dynamic: true,
  name: 'car-info',
  store,
  namespaced: true,
})

class CarInfo extends VuexModule {
  /**
   * Работа со статусами - чипсами
   */
  requestStatusId: number | string | undefined = ''

  penaltyStatusId: number | string | undefined = ''

  get requestStatuses (): CountResource[] {
    if(this.carInfo.request?.length){
      return [
        {
          value: 'placement',
          name: 'размещено',
          count: this.carInfo.request.filter((item: RequestResource) => item.status === 'placement').length,
        },
        {
          value: 'in_work',
          name: 'в работе',
          count:  this.carInfo.request.filter((item: RequestResource) => item.status === 'in_work').length,
        },
        {
          value: 'done',
          name: 'выполнено',
          count:  this.carInfo.request.filter((item: RequestResource) => item.status === 'done').length,
        },
      ]
    } else {
      return []
    }
  }

  get penaltyStatuses (): CountResource[] {
    if(this.carInfo.penalty?.length){
      return [
        {
          value: PenaltyStatusEnum.CHALLENGING,
          name: 'оспаривается',
          count: this.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.CHALLENGING).length,
        },
        {
          value: PenaltyStatusEnum.NOT_PAID,
          name: 'неоплачено',
          count: this.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.NOT_PAID).length,
        },
        {
          value: PenaltyStatusEnum.PAID,
          name: 'оплачено',
          count: this.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.PAID).length,
        },
        {
          value: PenaltyStatusEnum.NEW,
          name: 'новые',
          count: this.carInfo.penalty.filter((item: PenaltyShortResource) => item.status === PenaltyStatusEnum.NEW).length,
        },
      ]
    } else {
      return []
    }
  }

  @Mutation
  setRequestStatusId (payload: number | string | undefined) {
    Vue.set(this, 'requestStatusId', payload)
  }

  @Mutation
  setPenaltyStatusId (payload: number | string | undefined) {
    Vue.set(this, 'penaltyStatusId', payload)
  }


  /**
   * Работа с документами
   */

  carInfo: VehicleDataResource = {
    car: '',
    brand: '',
    carCharacteristic: {
      id: 0,
      vin: '',
      stateNumber: '',
      carStatus: '',
      maintenanceProgram: '',
      insuranceLitigationProgram: '',
      tireProgram: '',
      haulTireFitting: false,
      replacementCar: false,
      currentMileage: 0,
      regDateCurrentMileage: '',
      overMileageCompensation: 0,
      stsEndDate: '',
      productionYear: '',
      overrunCompensation: '',
      region: '',
    },
    termsOfAnAgreement: {
      id: 0,
      orderNumber: '',
      frameworkAgreementWithBuyer: '',
      leaseTerm: 0,
      mileageContract: 0,
      tenant: '',
      monthlyRentalPayment: 0,
      plannedReturnDate: '',
      dateOfActOfTransfer: '',
      dateOfActOfReturn: '',
      coordinator: {
        name: '',
        lastName: '',
        value: '',
      },
      manager: null,
    },
    carDrivers: [],
    insurancePolicy: [],
    request: [],
    penalty: [],
  }

  @Action({ rawError: true })
  async getInfo (carId: number) {
    const { data } = await CarParksCarCarIdGetRequest(carId)

    this.setInfo(data)
  }

  @Mutation
  setInfo (payload: any) {
    Vue.set(this, 'carInfo', payload)
  }
}

const CarInfoModule = getModule(CarInfo)

export default CarInfoModule
