









































































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Types
import { CarCharacteristicResource } from '@/store/types'

// Helpers
import { parseDate } from '@/utils/functions'

@Component
export default class CarCharacteristics extends Vue {
  @Prop({ required: true })
  private carCharacteristics!: CarCharacteristicResource

  private get innerValue (): CarCharacteristicResource {
    return {
      ...this.carCharacteristics,
      regDateCurrentMileage: parseDate(this.carCharacteristics.regDateCurrentMileage),
      stsEndDate: parseDate(this.carCharacteristics.stsEndDate),
    }
  }
}
