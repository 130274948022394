



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { handlePhoneNumber } from '@/utils/functions'

@Component
export default class CarDrivers extends Vue {
  @Prop({ required: true })
  private carDrivers!: any

  // обработка строки номера для ссылки
  private handlePhoneNumberWrapper (type: string, phone: string) {
    return handlePhoneNumber(type, phone)
  }

}
