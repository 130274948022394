















































// CORE
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import CarPenaltyCardHeader from '@/components/data/CarInfoExpansionPanelsContent/CarPenalty/CarPenaltyCardHeader.vue'
import CarPenaltyCardContent from '@/components/data/CarInfoExpansionPanelsContent/CarPenalty/CarPenaltyCardContent.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'

// STORE
import CommonModule from '@/store/modules/common'

// INTERFACES
import { PenaltyResource } from '@/store/types'

// HELPERS
import FleetPenaltyModule from '@/store/modules/fleet/penalty'
import NotifyMixin from '@/mixins/NotifyMixin'

interface PenaltyResourceWithChecked extends PenaltyResource {
  checked: boolean,
}

@Component({
  components: {
    CarPenaltyCardHeader,
    CarPenaltyCardContent,
    FiltersPanel,
  },
})
export default class CarPenalties extends Mixins(SystemMixin, NotifyMixin) {
  @Prop({ default: () => ([]) })
  private items!: PenaltyResourceWithChecked[]

  private innerValue: PenaltyResourceWithChecked[] = []

  private get checkedItems (): PenaltyResourceWithChecked[] {
    return this.innerValue.filter(item => item.checked)
  }

  private get warnCounter () {
    return this.checkedItems.filter(item => !item.fileLink || item.fileLink.includes('.jpg')).length
  }

  private get isPDFDownloadBtnDisable () {
    return this.checkedItems.length <= 0 || this.checkedItems.length <= this.warnCounter
  }

  private get isPaymentBtnDisabled (): boolean {
    if (this.checkedItems && this.checkedItems.length) {
      const vin = this.checkedItems[0].vin
      const items = this.checkedItems.filter(item => {
        return (
          item.vin === vin && item.paid
        )
      }).length

      return items !== this.checkedItems.length
    } else {
      return true
    }
  }

  private get indeterminate (): boolean {
    const vixibleCounter = this.checkedItems.length
    return this.innerValue.length !== vixibleCounter && vixibleCounter !== 0
  }

  private get all (): boolean {
    const vixibleCounter = this.checkedItems.length

    return this.innerValue.length === vixibleCounter && this.innerValue.length !== 0
  }

  private set all (value: boolean) {
    this.innerValue.forEach(item => {
      item.checked = value
    })
  }

  private created () {
    this.innerValue = this.items.map(item => ({ ...item, checked: false }))
  }

  private paymentPenalties () {
    const payload = { params: { penaltyIds: this.checkedItems.map(item => item.id) }, user: { role: this.role, id: this.entity } }
    CommonModule.paymentPenalties(payload)
      .then((link) => {
        const tab = window.open(link, '_blank')
        if (tab) {
          tab.focus()
        }
      })
  }

  private downloadMultiplePenalties () {
    FleetPenaltyModule.getPenaltiesPDFLink({
      ids: this.checkedItems.filter(item => !!item.fileLink).map(item => item.id),
    })
      .then(data => {
        if (!data.errors && data.link) {
          const tab = window.open(data.link, '_blank')

          if (tab) {
            tab.focus()
          }
        } else if (data.errors && this.checkedItems.length > data.errors.length + this.warnCounter) {
          this.$openModal(
            'PDFDownloadModal',
            'small-no-absolute',
            {
              exportURL: data.link,
              checkedItems: this.checkedItems,
              errorItems: data.errors,
            },
          )
        } else {
          this.notifyError('Выбранные файлы не могут быть выгружены в общий pdf. Попробуйте по отдельности.')
        }
      })
  }

  @Watch('items')
  private penaltiesWatcher () {
    this.innerValue = this.items.map(item => ({ ...item, checked: false }))
  }

  @Watch('warnCounter')
  private warnCounterWatcher () {
    const alias = 'penaltyWarnCounter'

    if (this.warnCounter > 0) {
      this.notifyWarn(`Выбраны штрафы (${this.warnCounter}) без возможности выгрузки в PDF из-за отсутствия документов или неверного формата файла`, alias)
    } else {
      this.removeNotifyByAlias(alias)
    }
  }
}
