import { render, staticRenderFns } from "./CarTermsOfAgreement.vue?vue&type=template&id=c4c7533c&scoped=true&lang=pug&"
import script from "./CarTermsOfAgreement.vue?vue&type=script&lang=ts&"
export * from "./CarTermsOfAgreement.vue?vue&type=script&lang=ts&"
import style0 from "./CarTermsOfAgreement.vue?vue&type=style&index=0&id=c4c7533c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4c7533c",
  null
  
)

export default component.exports