




























































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Types
import { TermsOfAnAgreementResource } from '@/store/types'

// Helpers
import { parseDate } from '@/utils/functions'

@Component
export default class CarTermsOfAgreement extends Vue {
  @Prop({ required: true })
  private termsOfAgreement!: TermsOfAnAgreementResource

  private get innerValue (): TermsOfAnAgreementResource {
    return {
      ...this.termsOfAgreement,
      plannedReturnDate: parseDate(this.termsOfAgreement.plannedReturnDate),
      dateOfActOfTransfer: parseDate(this.termsOfAgreement.dateOfActOfTransfer),
      dateOfActOfReturn: parseDate(this.termsOfAgreement.dateOfActOfReturn),
    }
  }
}
