











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { handlePhoneNumber, parseDate } from '@/utils/functions'
import { StatusRequestEnum, UserRequestShortResource } from '@/store/types'

@Component
export default class CarDrivers extends Vue {
  @Prop({ required: true })
  private carRequests!: any

  private statusObject (orderStatus: string): { color: string, label: string } {
    switch (orderStatus) {
    case StatusRequestEnum.PLACEMENT :
      return {
        color: 'orange',
        label: 'размещено',
      }
    case StatusRequestEnum.IN_WORK :
      return {
        color: 'blue',
        label: 'в работе',
      }
    case StatusRequestEnum.DONE :
      return {
        color: 'green',
        label: 'выполнено',
      }
    default:
      return {
        color: 'red',
        label: 'ошибка',
      }
    }
  }

  private parseDate = parseDate

  private parseUserName (user: UserRequestShortResource) {
    let result = ''

    if (user.name) {
      result += user.name + ' '
    }

    if (user.surname) {
      result += user.surname.slice(0,1) + '. '
    }

    if (user.patronymic) {
      result += user.patronymic.slice(0,1) + '.'
    }

    return result.trim() ? result.trim() : 'Нет данных'
  }

  // обработка строки номера для ссылки
  private handlePhoneNumberWrapper (type: string, phone: string) {
    return handlePhoneNumber(type, phone)
  }
}
