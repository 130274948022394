





















// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import NotifyMixin from '@/mixins/NotifyMixin'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import ExpansionPanelsCarInfo from '@/components/data/ExpansionPanelsCarInfo.vue'

//STORE
import SystemMixin from '@/mixins/SystemMixin'
import CarInfoModule from '@/store/modules/fleet/car-info'


@Component({
  components: {
    ExpansionPanelsCarInfo,
    DefaultLayout,
    CaptionCard,
  },
})
export default class CarInfoLayout extends Mixins(NotifyMixin, SystemMixin) {

  /**
   * LOCAL DATA
   */
  private breadcrumbs = [
    {
      name: 'Автопарк',
      value: 'carpark',
    },
  ]

  /**
   * COMPUTED PROPERTIES
   */
  private get carInfo () {
    return CarInfoModule.carInfo
  }

  /**
   * METHODS
   */

  /**
   * LIFECYCLE HOOKS
   */
  private created () {
    CarInfoModule.getInfo(+this.carId)
  }

  private fetchCarInfo () {
    CarInfoModule.getInfo(+this.carId)
  }

  /**
   * WATCHERS
   */
}
